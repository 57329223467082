import { ApplicationScope, Dimension, Padding } from "@superblocksteam/shared";
import _ from "lodash";
import React from "react";
import { UpdateWidgetPropertiesPayload } from "legacy/actions/controlActions";
import {
  WidgetAddChild,
  WidgetAddChildIfNotExists,
  WidgetDelete,
} from "legacy/actions/pageActions";
import { deleteWidgets } from "legacy/actions/widgetActions";
import { EventType } from "legacy/constants/ActionConstants";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import {
  GridDefaults,
  WidgetType,
  WidgetTypes,
} from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import { WidgetPropertyValidationType } from "legacy/constants/WidgetValidation";
import { APP_MODE } from "legacy/reducers/types";
import { GeneratedTheme } from "legacy/themes";
import { dimensionToGridRows } from "legacy/utils/WidgetPropsUtils";
import { createRunEventHandlersPayloadOptional } from "legacy/utils/actions";
import { generateReactKey } from "legacy/utils/generators";
import BaseWidget, { WidgetState } from "../BaseWidget";
import WidgetFactory from "../Factory";
import { WidgetOperations } from "../WidgetOperations";
import {
  getCanvasMinHeightFlattened,
  borderIsOverlay,
  getBorderThickness,
  getWidgetDefaultPadding,
} from "../base/sizing";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { FlattenedWidgetLayoutMap } from "../shared";
import { typographyProperties } from "../styleProperties";
import { getLineHeightInPxFromTextStyle } from "../typographyUtils";
import withMeta from "../withMeta";
import { TabsComponentWithLayoutManaged } from "./TabsComponentWithLayoutManaged";
import { DEFAULT_TABS_WIDGET_HEADER_STYLE_VARIANT } from "./constants";
import { TabContainerWidgetProps, TabsWidgetProps } from "./types";
import { computeTabHeaderHeightPx } from "./utils";
import type {
  CanvasWidgetsReduxState,
  WidgetActionHookSync,
  WidgetActionResponse,
} from "../Factory";
import type { DynamicWidgetsVisibilityState } from "legacy/selectors/visibilitySelectors";

const actionHooks: WidgetActionHookSync = function (params: {
  widgetId: string;
  widgets: Readonly<CanvasWidgetsReduxState>;
  action: ReduxAction<
    WidgetAddChild | WidgetDelete | UpdateWidgetPropertiesPayload
  >;
}) {
  const { widgetId, widgets, action } = params;
  if (widgets[widgetId].type !== WidgetTypes.TABS_WIDGET) {
    return;
  }
  const updates: WidgetActionResponse = [];
  const widget = widgets[widgetId] as unknown as Readonly<
    TabsWidgetProps<TabContainerWidgetProps>
  >;
  switch (action.type) {
    case ReduxActionTypes.WIDGET_CREATE: {
      const payload = action.payload as WidgetAddChild;
      // Do not handle widget create for tabs widget other than the current widget
      if (payload.newWidgetId !== widgetId) {
        return;
      }
      if (payload.type !== WidgetTypes.TABS_WIDGET) {
        // Don't re-initialize the tabs when the children are modified
        break;
      }
      const tabs = [...widget.tabs];

      const newTabs: typeof tabs = tabs.map((tab) => {
        const newTab = { ...tab };
        newTab.widgetId = generateReactKey();
        return newTab;
      });
      updates.push({
        widgetId,
        widget: {
          ...widget,
          tabs: newTabs,
        } satisfies typeof widget as any,
      });

      break;
    }
    case deleteWidgets.type: {
      const payload = action.payload as WidgetDelete;
      const deletedTabs = (payload.widgetIds ?? []).filter(
        (deletedId) => widgets[deletedId]?.parentId === widgetId,
      );
      if (deletedTabs.length > 0) {
        const newTabs = widget.tabs.filter(
          (tab) => !deletedTabs.includes(tab.widgetId),
        );
        updates.push({
          widgetId,
          widget: {
            ...widget,
            tabs: newTabs,
          } satisfies typeof widget as any,
        });
      }
      break;
    }
    default:
      break;
  }
  return updates;
};

class TabsWidget extends BaseWidget<
  TabsWidgetProps<TabContainerWidgetProps>,
  WidgetState
> {
  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "tabs",
            label: "Tabs",
            headerControlType: "ADD_TAB",
            controlType: "TABS_INPUT",
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            propertyName: "defaultTab",
            helpText: "Selects a tab name specified by default",
            placeholderText: "Enter tab name",
            label: "Default tab",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            propertyName: "shouldShowTabs",
            label: "Tab header",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          ...typographyProperties({
            defaultVariant: DEFAULT_TABS_WIDGET_HEADER_STYLE_VARIANT,
            textStyleParentDottedPath: "headerProps",
            propertyNameForHumans: "Tab header",
            hiddenIfPropertyNameIsNullOrFalse: "shouldShowTabs",
          }),
        ],
      },
      sizeSection({ heightSupportsFitContent: true }),
      {
        sectionName: "Layout",
        children: [
          {
            propertyName: "shouldScrollContents",
            propertyCategory: PropsPanelCategory.Layout,
            label: "Scroll contents",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
            hidden: (props: TabsWidgetProps<TabContainerWidgetProps>) =>
              props.height.mode === "fitContent",
          },
          ...visibleProperties({ useJsExpr: false }),
        ],
      },
      {
        sectionName: "Actions",
        sectionCategory: PropsPanelCategory.EventHandlers,
        children: [
          getPopoverConfig(
            "onTabSelected",
            "Triggers an action when the tab is changed",
          ),
        ],
      },
    ];
  }
  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      tabs: VALIDATION_TYPES.TABS_DATA,
      defaultTab: VALIDATION_TYPES.SELECTED_TAB,
    };
  }

  onTabChange = (tabWidgetId: string) => {
    this.props.updateWidgetMetaProperty(
      "selectedTabWidgetId",
      tabWidgetId,
      createRunEventHandlersPayloadOptional({
        steps: this.props.onTabSelected,
        currentScope: ApplicationScope.PAGE,
        type: EventType.ON_TAB_CHANGE,
        entityName: this.props.widgetName,
      }),
    );
  };

  static getDerivedPropertiesMap() {
    return {
      selectedTab: `{{ this.tabs?.find(tab => tab.widgetId === this.selectedTabWidgetId) }}`,
      defaultTabWidgetId: `{{(() => {
    const defaultTab = this.tabs?.find((tab) => tab.label === this.defaultTab);
    return defaultTab ? defaultTab.widgetId : this.tabs?.[0]?.widgetId;
  })()}}`,
    };
  }

  static getMetaPropertiesMap() {
    return {
      selectedTabWidgetId: undefined,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      selectedTabWidgetId: "defaultTabWidgetId",
    };
  }

  static applyActionHook: WidgetActionHookSync = actionHooks;

  getPageView() {
    const selectedTabWidgetId = this.props.selectedTabWidgetId;
    const childWidgetData: TabContainerWidgetProps = this.props.children
      ?.filter(Boolean)
      .filter((item) => {
        return selectedTabWidgetId === item.widgetId;
      })[0];

    return (
      <TabsComponentWithLayoutManaged
        {...this.props}
        onTabChange={this.onTabChange}
        shouldUseBorderOutline={(theme: GeneratedTheme) =>
          borderIsOverlay(this.props, theme, childWidgetData)
        }
      >
        {this.renderComponent(childWidgetData)}
      </TabsComponentWithLayoutManaged>
    );
  }

  renderComponent = (childWidgetData: TabContainerWidgetProps) => {
    if (!childWidgetData) {
      return null;
    }
    return WidgetFactory.createWidget(childWidgetData, this.props.appMode);
  };

  getWidgetType(): WidgetType {
    return WidgetTypes.TABS_WIDGET;
  }

  addTabContainer = (widgetIds: string[]) => {
    widgetIds.forEach((newWidgetId: string) => {
      const tab = this.props.tabs.find((tab) => tab.widgetId === newWidgetId);
      if (tab) {
        const width = this.props.width;
        const height = Dimension.minus(
          dimensionToGridRows(this.props.height),
          Dimension.gridUnit(3),
        ).asFirst();
        const config = {
          type: WidgetTypes.CANVAS_WIDGET,
          position: {
            left: Dimension.gridUnit(0),
            top: Dimension.gridUnit(0),
          },
          size: {
            height: height,
            width: width,
          },
          newWidgetId,
          widgetId: this.props.widgetId,
          props: {
            tabId: tab.id,
            tabName: tab.label,
            canExtend: false,
            detachFromLayout: true,
            children: [],
          },
        } satisfies WidgetAddChild;
        this.updateWidget(
          WidgetOperations.WIDGET_CREATE,
          this.props.widgetId,
          config,
        );
      }
    });
  };

  removeTabContainer = (widgetIds: string[]) => {
    widgetIds.forEach((widgetIdToRemove: string) => {
      this.updateWidget(WidgetOperations.WIDGET_DELETE, widgetIdToRemove, {
        widgetIds: [widgetIdToRemove],
      });
    });
  };

  componentDidUpdate(prevProps: TabsWidgetProps<TabContainerWidgetProps>) {
    if (
      this.props.tabs.length !== prevProps.tabs.length &&
      this.props.children.length !== this.props.tabs.length
    ) {
      const tabWidgetIds = this.props.tabs.map((tab) => tab.widgetId);
      const childWidgetIds = this.props.children
        .filter(Boolean)
        .map((child) => child.widgetId);
      // If the tabs and children are different,
      // add and/or remove tab container widgets

      if (!this.props.invalidProps?.tabs) {
        if (_.xor(childWidgetIds, tabWidgetIds).length > 0) {
          const widgetIdsToRemove: string[] = _.without(
            childWidgetIds,
            ...tabWidgetIds,
          );
          const widgetIdsToCreate: string[] = _.without(
            tabWidgetIds,
            ...childWidgetIds,
          );
          this.addTabContainer(widgetIdsToCreate);
          this.removeTabContainer(widgetIdsToRemove);
        }

        // If all tabs were removed.
        if (tabWidgetIds.length === 0) {
          const newTabContainerWidgetId = generateReactKey();
          const tabs = [
            { id: "tab1", widgetId: newTabContainerWidgetId, label: "Tab 1" },
          ];
          this.updateWidgetProperties({ tabs: tabs });
        }
      }
    }
  }

  generateTabContainers = () => {
    const { tabs, widgetId } = this.props;
    const childWidgetIds = this.props.children
      ?.filter(Boolean)
      .map((child) => child.widgetId);
    let tabsToCreate = tabs || [];
    if (childWidgetIds && childWidgetIds.length > 0) {
      tabsToCreate = tabs.filter(
        (tab) => childWidgetIds.indexOf(tab.widgetId) === -1,
      );
    }

    if (!tabsToCreate || tabsToCreate.length === 0) {
      return;
    }

    const tabContainers = tabsToCreate.map(
      (tab) =>
        ({
          type: WidgetTypes.CANVAS_WIDGET,
          tabId: tab.id,
          tabName: tab.label,
          widgetId: tab.widgetId,
          parentId: widgetId,
          detachFromLayout: true,
          parentRowSpace: 1,
          parentColumnSpace: this.props.parentColumnSpace,
          size: {
            width: this.props.width,
            height: Dimension.minus(
              dimensionToGridRows(this.props.height),
              Dimension.gridUnit(3),
            ).asFirst(), // tabs header takes 3 rows
          },
          position: {
            left: Dimension.gridUnit(0),
            top: Dimension.gridUnit(0),
          },
          isLoading: false,
        } satisfies WidgetAddChildIfNotExists["children"][number]),
    );

    this.updateWidget(
      WidgetOperations.WIDGET_ADD_CHILD_IF_NOT_EXISTS,
      widgetId,
      {
        children: tabContainers,
      },
    );
  };

  componentDidMount() {
    // TODO: move the logic to componentDidMount
    this.generateTabContainers();
  }

  static computeMinHeightFromProps(
    props: Omit<TabsWidgetProps<any>, "children">,
    widgets: CanvasWidgetsReduxState | FlattenedWidgetLayoutMap,
    theme: GeneratedTheme,
    appMode: APP_MODE,
    _dynamicVisibility: DynamicWidgetsVisibilityState,
  ): Dimension<"px"> | undefined {
    const lineHeightPx = getLineHeightInPxFromTextStyle({
      textStyleVariant: props.headerProps?.textStyle?.variant,
      nestedProps: props.headerProps?.textStyle,
      typographies: theme.typographies,
      defaultTextStyleVariant: DEFAULT_TABS_WIDGET_HEADER_STYLE_VARIANT,
    });

    const tabHeight = computeTabHeaderHeightPx(
      lineHeightPx,
      props.shouldShowTabs,
    );

    const selectedTabWidgetId = props.selectedTabWidgetId;
    const tab = widgets[selectedTabWidgetId];

    // Tab might be null if the evaluator has not run yet, it will self resolve
    let tabContentHeight = tab
      ? getCanvasMinHeightFlattened(tab, widgets).value
      : 0;
    if (tabContentHeight <= 0) {
      tabContentHeight =
        appMode === APP_MODE.EDIT
          ? 6 * GridDefaults.DEFAULT_GRID_ROW_HEIGHT
          : 0;
    }

    const paddingY = Padding.y(
      tab?.padding ?? getWidgetDefaultPadding(theme, tab),
    ).value;
    const borderWidth = getBorderThickness(props, theme, tab) * 2;

    return Dimension.px(tabHeight + tabContentHeight + paddingY + borderWidth);
  }
}

export default TabsWidget;
export const ConnectedTabsWidget = withMeta(TabsWidget);
